<template>
  <div>
    <div v-if="building.image" class="image-tooltip">
    <div class="header">{{building.value}}</div>
    <div class="body">
      <a :href="building.image" data-fancybox="">
        <b-img  :src="building.image" fluid alt="Fluid image"></b-img>
      </a>
    </div>
  </div>
  <div v-if="!building.image && building.status === 'Available'" class="copy-tooltip">
    <div class="header">{{building.value}}</div>
    <div class="body">
      <div class="subtitle">Area in Sq.ft</div>
      <div class="area">{{building.area}}</div>
    </div>
  </div>
  <div v-if="!building.image && building.status === 'Booked'" class="copy-tooltip">
    <div class="header">{{building.value}}</div>
    <div class="body">
      <div class="badge-booked">{{building.status}}</div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    building: {
      type: Object,
      required: true,
    },
    // booked_plots: {
    //   type: Array,
    //   required: true
    // },
    // blocked_plots: {
    //   type: Array,
    //   required: true
    // },
  },
//  computed: {
//     booked_flat_details() {
//       return this.booked_plots.length > 0 ? this.booked_plots.map(b => b.key).includes(this.building.key) : ''
//     },
//     blocked_flat_details() {
//       return this.blocked_plots.length > 0 ? this.blocked_plots.map(b => b.key).includes(this.building.key) : ''
//     },
//  }
}
</script>
<style scoped>
  .header{
    background-color: #58A4B0;
    color: #fff;
    font-size: 13px;
    text-align: left;
    padding: 6px 8px;
    line-height: 14px;
  }

  .copy-tooltip .body{
    padding: 6px 8px;
  }

  .subtitle{
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    font-weight: 500;
  }
  .area{
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }

  .badge-booked{
    color: #EA3535;
    font-size: 10px;
    font-weight: 500;
    border-radius: 2px;
    background: rgba(234, 53, 53, 0.1);
    margin: 2px;
    padding: 2px 4px;
    text-align: center;
  }
</style>